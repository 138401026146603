/* eslint-disable */
import React from 'react'
import Link from 'gatsby-link'
import Layout from '../components/layout'
import { graphql } from 'gatsby'

import SEO from "../components/seo.js";
import Header from "../components/header.js";
import GeneralFooter from '../components/generalFooter';
import Offers from "../components/offers";

// Import paragraphs
import ProductList from '../paragraphs/product_list.js';
import CenteredTextContent from '../paragraphs/centered_text_content.js';
import ImageLeftTextRightHeader from '../paragraphs/image_left_text_right_header.js';

import sendEvent from '../helpers/sendAnalytics';

class ProductPage extends React.Component {
  render() {
    const data = this.props.data.nodePage
    const blogs = this.props.data.allNodeBlog.edges
    const retailers = this.props.data.allNodeStore.edges
    return (
      <Layout className="page-wrapper products-page">
        <SEO meta={data.field_meta_tags} />
        <Header retailers={retailers}></Header>
        <div style={{clear:'both'}} />
        {data.relationships.field_paragraph.map(paragraph => {
          switch(paragraph.__typename) {
            case 'paragraph__image_left_text_right_header':
              return (
                <ImageLeftTextRightHeader key={paragraph.id} data={paragraph} />
              );
              break;
            case 'paragraph__centered_text_content':
              return (
                <CenteredTextContent key={paragraph.id} data={paragraph} blogs={this.props.data.allNodeBlog} />
              );
              break;
            case 'paragraph__product_list':
              return (
                <ProductList key={paragraph.id} data={paragraph} />
              );
              break;
          }
        })}

        <div className="text-with-three-col-grid products pb-100">
          <div className="text-with-three-col-grid-wrapper">

            <div className="text-with-three-col-grid-text">
              <div className="section-title text-right-title">Bring Comfort Home<span className="sub-title text-right-sub-title">Find style inspiration</span></div>
            </div>

            <div className="text-with-three-col-column-grid">
              {blogs.map(blog => {
                return (
                  <Link
                    to={blog.node.path.alias}
                    key={blog.node.id}
                    className="column-item blog-col"
                    onClick={() => sendEvent({
                      ga: {
                        category: 'Engagement',
                        action: 'Click',
                        label: 'Product_BCH_CTA'
                      }
                    })}
                  >
                    <div className="column-item-image_wrapper">
                      <div className="column-item-image" style={(blog.node.relationships.field_image && blog.node.relationships.field_image.localFile) ? {backgroundImage: `url(${blog.node.relationships.field_image.localFile.childImageSharp.resolutions.src})`} : {}}></div>
                    </div>

                    <div className="colum-item-text">
                      <div className="text-link-title">{blog.node.title}</div>
                      <div className="text-link-details">
                        <div className="text-link-date">{blog.node.created}</div>
                        <div className="text-link-author">by <span className="text-link-author-name">{blog.node.field_author}</span></div>
                      </div>
                    </div>
                  </Link>
                )
              })}
            </div>
          </div>
        </div>
        <Offers />
        <GeneralFooter />
      </Layout>
    )
  }
}

export default ProductPage

export const query = graphql`
query Products {
  nodePage(uuid: {eq:"de4813f4-7584-4911-b85a-7f79421689ee"}) {
    id
    title
    field_meta_tags
    relationships {
      field_paragraph {
        __typename
        ... on paragraph__image_left_text_right_header {
          id
          field_heading_title {
            value
          }
          field_sub_title
          field_body_text {
            value
          }
          field_list_items
          relationships {
            field_image {
              localFile {
                childImageSharp {
                  resolutions(width:730, quality: 85) {
                    src
                  }
                }
              }
            }
          }
        }
        ... on paragraph__centered_text_content {
          id
          field_title
          field_body_text {
            value
          }
        }
        ... on paragraph__product_list {
          relationships {
            field_product {
              id
              title
              field_preview_description {
                value
              }
              path {
                alias
              }
              relationships {
                field_image {
                  localFile {
                    childImageSharp {
                      resolutions(width:380, quality: 85) {
                        src
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  allNodeBlog(limit:3, sort:{fields:[created], order: DESC}) {
    edges {
      node {
        id
        title
        created(formatString: "MMM DD YYYY")
        field_author
        path {
          alias
        }
        relationships {
          field_image {
            localFile {
              childImageSharp {
                resolutions(width: 600, quality: 85) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
  allNodeStore {
    edges {
      node {
        id
        title
        relationships {
          field_image {
            localFile {
              childImageSharp {
                resolutions(width: 143) {
                  src
                }
              }
            }
          }
          field_state {
            name
          }
        }
      }
    }
  }
}
`
